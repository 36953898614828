import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import {
  errorMessages,
  successMessages,
} from "../../messages/commonMessages.jsx";
import { useParametrosService } from "../../../service/parametros.js";

export const useParametros = () => {
  const [descripcion, setDescripcion] = useState("");
  const [valor, setValor] = useState("");
  const [categoria, setCategoria] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdd, setIsAdd] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isView, setIsView] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const { get, getParametrosBusqueda } = useParametrosService();

  useEffect(() => {
    if (isSearch) {
      getParametros();
    }

    return () => {
      setIsSearch(false);
    };
  }, [isSearch]);

  const getParametros = async () => {
    try {
      setLoading(true);
      if (descripcion || valor || categoria) {
        await getParametrosBusqueda(descripcion, valor, categoria).then(
          (response) => {
            if (Array.isArray(response)) {
              setData(response);
            } else {
              showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
            }
          }
        );
      } else {
        await get().then((response) => {
          if (Array.isArray(response)) {
            setData(response);
          } else {
            showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
          }
        });
      }
    } catch (error) {
      showAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
    } finally {
      setLoading(false);
    }
  };

  const showAlert = (severity, title, children) => {
    setAlert({ severity, title, children });

    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  const handleSave = () => {
    handleCloseDialog();
    showAlert("success", "Exitoso", successMessages.CHANGES_SAVED);
    setIsSearch(true);
  };

  const handleAdd = () => {
    setIsDialogOpen(true);
    setIsAdd(true);
    setSelectedRow("");
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsEdit(false);
    setIsAdd(false);
    setIsView(false);
  };

  const handleCancel = () => {
    setLoading(false);
    handleCloseDialog();
  };

  const handleConsult = () => {
    setIsDialogOpen(true);
    setIsView(true);
  };

  const handleEdit = () => {
    setIsDialogOpen(true);
    setIsEdit(true);
  };

  const handleRowSelectionChange = (selectedRowIds, data) => {
    const selectedIDs = new Set(selectedRowIds);
    const selectedRow = data.filter((row) => selectedIDs.has(row.id));
    setSelectedRow(selectedRow);
  };

  const handleParametroSearch = async () => {
    setIsSearch(true);
  };

  const handleGenerateReport = () => {
    showAlert("success", "Exitoso", successMessages.DOWNLOAD_SUCCESSFUL);
  };

  const handleClean = () => {
    setDescripcion("");
    setValor("");
    setCategoria("");
  };

  const onChangeDescripcion = async ({ target }) => {
    const { value } = target;
    setDescripcion(value);
  };

  const onChangeValor = async ({ target }) => {
    const { value } = target;
    setValor(value);
  };

  const onChangeCategoria = async ({ target }) => {
    const { value } = target;
    setCategoria(value);
  };

  const columnas = [
    { field: "dscParametro", headerName: "Descripción", flex: 1 },
    { field: "valParametro", headerName: "Valor", flex: 1 },
    { field: "categoria", headerName: "Categoría", flex: 1 },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <div className="button-action">
          <Tooltip title="Consultar">
            <button
              id="btn-consultar"
              className="button-table"
              onClick={handleConsult}
            >
              <SearchIcon />
            </button>
          </Tooltip>
          <Tooltip title="Modificar">
            <button
              id="btn-editar"
              className="button-table"
              onClick={handleEdit}
            >
              <EditIcon />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return {
    handleRowSelectionChange,
    handleParametroSearch,
    handleGenerateReport,
    handleSave,
    handleAdd,
    handleCancel,
    handleCloseDialog,
    handleClean,
    onChangeDescripcion,
    onChangeValor,
    onChangeCategoria,
    getParametros,
    isDialogOpen,
    isEdit,
    isView,
    isAdd,
    loading,
    selectedRow,
    data,
    columnas,
    descripcion,
    valor,
    categoria,
    alert,
  };
};
