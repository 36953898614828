import { useState } from 'react';
import { useComisionService } from '../../../../service/comision';
import { useComisiones } from '../useComisiones';
import { errorMessages, warningMessages } from '../../../../components/messages/commonMessages';

export const useFormulario = (onSave, onCancel, comision, render) => {
    const [descripcion, setDescripcion] = useState(comision !== '' ? comision[0].dscComision : '');
    const [codigoInterno, setCodigoInterno] = useState(comision !== '' ? comision[0].codComision : '');
    const [alert, setAlert] = useState(null);
    const [form, setForm] = useState({ message: "" });
    const { post, put } = useComisionService();
    const { getComisiones } = useComisiones();

    let values = {};
    const addComisiones = async (values) => {
        const result = await post(values);

        return result;
    }
    const updateComisiones = async (idComision, values) => {
        const result = put(idComision, values);
        return result;
    }
    const handleChangeInput = async (event) => {
        const { value, maxLength } = event.target;

        if (value.length >= maxLength) {
            showAlert("warning", "Advertencia", warningMessages.MAX_LENGTH(maxLength));
        }

        setForm((prevForm) => ({
            ...prevForm,
            message: value.slice(0, maxLength),
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (descripcion && codigoInterno) {
            values.dscComision = descripcion;
            values.codComision = codigoInterno;
            values.indActivo = 1;
            values.usuCreacion = "maria.sanchez";
            if (render === 'Add') {
                const result = await addComisiones(values);
                if (result.data.code === 409) {
                    showAlert("error", "Error", result.data.msg);
                    return;
                } else {
                    await getComisiones();
                }

            } else {
                let idComision = comision[0].idComision
                if (idComision !== undefined) {
                    const result = await updateComisiones(idComision, values);
                    if (result.data.code === 409) {
                        showAlert("error", "Error", result.data.msg);
                        return;
                    } else {
                        await getComisiones();
                    }
                }
            }
            onSave();
            setDescripcion('');
            setCodigoInterno('');
        } else {
            alert(errorMessages.FORM_ERROR);
        }
    };
    const updateCodigoInterno = async (value) => {
        setCodigoInterno(value)
    }
    const updateDescripcion = async (value) => {
        setDescripcion(value)
    }
    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };
    return (
        { updateDescripcion, updateCodigoInterno, handleChangeInput, handleSubmit, descripcion, codigoInterno, alert, form }
    )
};