import React, { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { CollapsibleTable } from '../../Table/Collapse.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import { CustomDialog } from '../../messages/CustomDialog.jsx';
import { AlertDialog } from '../../messages/AlertDialog.jsx';
import { Alert } from '../../messages/Alert.jsx';
import { successMessages, confirmationMessages } from '../../messages/commonMessages.jsx';
import { Formulario } from './Formulario/Formulario.jsx';
import { FormularioDetalle } from './FormularioDetalle/FormularioDetalle.jsx';
import { useComisiones } from './useComisiones.js';
import Spinner from '../../spinner/LoadingSpinner.js';

const MantenimientoComisiones = () => {
    const { handleRowSelectionChange, getComisiones, handleSave, handleCloseDialog, columns, isDialogOpen,
        isEdit, isView, isAdd, alert, handleAdd, handleCancel, handleDeleteData, isDelete, selectedRow, data,
        handleSubRowSelectionChange, handleSubSave, handleSubCloseDialog, subColumns, formatoFecha,
        isSubDialogDialogOpen, isSubEdit, isSubView, isSubAdd, subAlert, handleDeleteDetalle,
        handleSubCancel, isSubDelete, selectedSubRow, detalle, loading } = useComisiones();

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };

    useEffect(() => {
        getComisiones();
    }, [loading]);

    return (
        <div className='content-wrapper'>
            {loading && <Spinner />}
            {alert && (
                <Alert
                    severity={alert.severity}
                    title={alert.title}
                >
                    {alert.message}
                </Alert>
            )}
            {subAlert && (
                <Alert
                    severity={subAlert.severity}
                    title={subAlert.title}
                >
                    {subAlert.message}
                </Alert>
            )}
            <div className="wrapper-card">
                <h1 style={{ color: '#4b516a' }}>
                    Mantenimiento Comisiones
                </h1>
                <div className='form-container'>
                    <div className="content">
                        <div className='form-container'>
                            <div className="title">
                                Lista de Comisiones
                            </div>
                            <div className="content">
                                <CollapsibleTable
                                    columns={columns}
                                    subColumns={subColumns}
                                    rows={data && Array.isArray(data) ? data.map(row => ({ id: row.idComision, ...row, fecCreacion: formatoFecha(row.fecCreacion) })) : []}
                                    subRows={detalle && Array.isArray(detalle) ? detalle.map(subRow => ({ id: subRow.idComisionTip, ...subRow })) : []}
                                    onSubRowSelect={(selectedSubRowIds) => handleSubRowSelectionChange(selectedSubRowIds, detalle)}
                                    onRowSelect={(selectedRowIds) => handleRowSelectionChange(selectedRowIds, data)} />
                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button id='btn-agregar-dialog' className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Comisión'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} comision={selectedRow} render={'Add'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Comisión'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} comision={selectedRow} render={'Edit'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Comisión'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} comision={selectedRow} render={'View'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}

                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Comisión"}
                                            message={<> {confirmationMessages.DELETE_CONFIRMATION_COMISION} <br /> <div style={{ marginLeft: '20%' }} > <b>Comisión:</b> {selectedRow[0].codComision} </div> </>}
                                            isOpen={isDialogOpen}
                                            onClose={handleCloseDialog}
                                            onAccept={handleDeleteData}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    {isSubDialogDialogOpen && isSubAdd && (
                                        <CustomDialog
                                            question={'Agregar Detalle de Comisiones'}
                                            message={<FormularioDetalle onSave={handleSubSave} onCancel={handleSubCancel} detalleComision={selectedSubRow} comision={selectedRow} render={'Add'} />}
                                            onClose={handleSubCloseDialog}
                                        />
                                    )}
                                    {isSubDialogDialogOpen && isSubEdit && (
                                        <CustomDialog
                                            question={'Modificar Detalle de Comisiónes'}
                                            message={<FormularioDetalle onSave={handleSubSave} onCancel={handleSubCancel} detalleComision={selectedSubRow} comision={selectedRow} render={'Edit'} />}
                                            onClose={handleSubCloseDialog}
                                        />
                                    )}
                                    {isSubDialogDialogOpen && isSubView && (
                                        <CustomDialog
                                            question={'Consultar Detalle De Comisiones'}
                                            message={<FormularioDetalle onSave={handleSubSave} onCancel={handleSubCancel} detalleComision={selectedSubRow} comision={selectedRow} render={'View'} />}
                                            onClose={handleSubCloseDialog}
                                        />
                                    )}
                                    {isSubDialogDialogOpen && isSubDelete && (
                                        <AlertDialog
                                            question={"Eliminar Detalle Comisión"}
                                            message={<> {confirmationMessages.DELETE_CONFIRMATION_DETALLE_COMISION} <br /> <div style={{ marginLeft: '20%' }} > <b>Monto Comisión:</b> {selectedSubRow[0].mtoComision} </div> </>}
                                            isOpen={isSubDialogDialogOpen}
                                            onClose={handleSubCloseDialog}
                                            onAccept={handleDeleteDetalle}
                                            onCancel={handleSubCancel}
                                        />
                                    )}
                                    <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoComisiones };