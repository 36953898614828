import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";

export const useCodigoService = () => {
    const { serverData } = useConectividadApi();
    const session = useSelector(state => state.session);

    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de codigo respuesta
   */
    const get = async () => {
        try {
            const options = {
                method: 'GET',
                url: '/codigos',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
    * @methodApi v.
    * @description  Método encargado de obtener codigo respuesta por Id
    */
    const getId = async (codigoId) => {
        try {
            const options = {
                method: 'GET',
                url: `/codigos/${codigoId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
    * @methodApi v.
    * @description  Método encargado de obtener codigo respuesta por codigo respuesta
    */
    const getCodRespuesta = async (codigo) => {
        try {
            const options = {
                method: 'GET',
                url: `/codigos/codRespuesta?codRespuesta=${codigo}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
* @methodApi post.
* @description  Método encargado de crear codigo respuesta
*/
    const post = async (values) => {
        try {
            const options = {
                method: 'POST',
                url: '/codigos',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
            };

            const response = await serverData(options);
            return { success: true, data: response };

        } catch (error) {
            return { success: false, error: error?.data || "Error desconocido" };
        }
    }
    /**
    * @methodApi v.
    * @description  Método encargado de actualizar codigo respuesta
    */
    const put = async (codigoId, values) => {
        try {
            const options = {
                method: 'PUT',
                url: `/codigos/${codigoId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
            };
            const response = await serverData(options);
            return { success: true, data: response };

        } catch (error) {
            return { success: false, error: error?.data || "Error desconocido" };
        }
    }

    /**
* @methodApi v.
* @description  Método encargado de eliminar codigo respuesta
*/
    const patch = async (codigoId) => {
        try {
            const options = {
                method: 'PATCH',
                url: `/codigos/${codigoId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
            };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }
    return {
        //Api codigo
        get,
        getId,
        getCodRespuesta,
        post,
        put,
        patch,
    }

}