import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";

export const useAgenciaService = () => {
    const { serverData } = useConectividadApi();
    const session = useSelector(state => state.session);

    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de agencias
   */
    const get = async () => {
        try {
            const options = {
                method: 'GET',
                url: '/agencias',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de recaudadores
   */
    const getRecaudadores = async () => {
        try {
            const options = {
                method: 'GET',
                url: '/agencias/recaudadores',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de canales
   */
    const getCanales = async () => {
        try {
            const options = {
                method: 'GET',
                url: '/agencias/canales',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
    * @methodApi v.
    * @description  Método encargado de obtener agencia por recaudadores
    */
    const getAgenciasRecaudadores = async (recaudadorId) => {
        try {
            const options = {
                method: 'GET',
                url: `/agencias/recaudador/${recaudadorId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    /**
* @methodApi post.
* @description  Método encargado de crear agencias
*/
    const post = async (values) => {
        try {
            const options = {
                method: 'POST',
                url: '/agencias',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
            };

            const response = await serverData(options);
            return { success: true, data: response };

        } catch (error) {
            return { success: false, error: error?.data || "Error desconocido" };
        }
    };
    /**
    * @methodApi v.
    * @description  Método encargado de actualizar agencias
    */
    const put = async (idAgencia, values) => {
        try {
            const options = {
                method: 'PUT',
                url: `/agencias/${idAgencia}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
            };

            const response = await serverData(options);
            return { success: true, data: response };

        } catch (error) {
            return { success: false, error: error?.data || "Error desconocido" };
        }
    }
    /**
    * @methodApi v.
    * @description  Método encargado de actualizar canales
    */
    const patch = async (idAgencia) => {

        try {
            const options = {
                method: 'PATCH',
                url: `/agencias/${idAgencia}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
            };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }

    }
    return {
        //Api agencias
        getRecaudadores,
        getCanales,
        getAgenciasRecaudadores,
        get,
        post,
        put,
        patch,
    }
}