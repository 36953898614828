import React, { useEffect } from 'react';
import { DataTable } from '../../Table/Table.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import { confirmationMessages } from '../../messages/commonMessages.jsx';
import { CustomDialog } from '../../messages/CustomDialog.jsx';
import '../../../App.css'
import { AlertDialog } from '../../messages/AlertDialog.jsx';
import { Alert } from '../../messages/Alert.jsx';
import { Formulario } from './Formulario/Formulario.jsx';
import Tooltip from '@mui/material/Tooltip';
import { useAgenciaRecaudadores } from './useAgenciaRecaudadores.js';
import Spinner from '../../spinner/LoadingSpinner.js';

const MantenimientoAgenciaRecaudadores = () => {

    const { handleRowSelectionChange, handleSave, handleCloseDialog, handleFind, setRecaudador, loading,
        columnas, isDialogOpen, isEdit, isView, isAdd, alert, handleGenerateReport, handleDeleteData, getAgencias,
        recaudador, handleAdd, handleCancel, isDelete, selectedRow, data, opciones } = useAgenciaRecaudadores();

    useEffect(() => {
        getAgencias();
    }, [loading]);

    return (
        <div className='content-wrapper'>
            {loading && <Spinner />}
            {alert && (
                <Alert
                    severity={alert.severity}
                    title={alert.title}
                    isForm={false}
                >
                    {alert.message}
                </Alert>
            )}
            <div className="wrapper-card">
                <h1>
                    Mantenimiento Agencias de Recaudadores
                </h1>
                <div className='form-container'>
                    <div className="content">
                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Recaudador:
                                        </label>
                                        <Tooltip title="Recaudador">
                                            <select className='select-form' value={recaudador || ""} onChange={(e) => setRecaudador(e.target.value)} >
                                                <option value="">Seleccione el recaudador</option>
                                                {opciones.map((opcion) => (
                                                    <option key={opcion.idRecaudador} value={opcion.idRecaudador}>
                                                        {opcion.nomRecaudador}
                                                    </option>
                                                ))}
                                            </select>
                                        </Tooltip>
                                    </div>
                                    <div className="button-filter">
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={handleFind}>Buscar</button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Agencias Recaudadores
                            </div>
                            <div className="content">
                                <DataTable
                                    data={data && Array.isArray(data) ? data.map(row => ({ id: row.idAgencia, ...row })) : []}
                                    columns={columnas}
                                    onRowSelectionChange={handleRowSelectionChange} >
                                    {data === null && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            Cargando datos...
                                        </div>
                                    )}
                                </DataTable>
                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Agencia Recaudador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} agencia={selectedRow} render={'Add'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Agencia Recaudador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} agencia={selectedRow} render={'Edit'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Agencia Recaudador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} agencia={selectedRow} render={'View'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Agencia Recaudador"}
                                            message={ <> {confirmationMessages.DELETE_CONFIRMATION_AGENCIA} <br/> <div style={{marginLeft: '20%'}} > <b>Agencia de Recaudador:</b> {selectedRow[0].codAgencia} </div> </> }
                                            isOpen={isDialogOpen}
                                            onClose={handleCloseDialog}
                                            onAccept={handleDeleteData}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoAgenciaRecaudadores };