import { useState } from 'react';
import { useCodigoService } from '../../../../service/codigo';
import { useCodigoRespuesta } from '../useCodigoRespuesta';
import { errorMessages, warningMessages } from '../../../../components/messages/commonMessages';

export const useFormulario = (onSave, onCancel, codigo, render) => {
    const [codigoRespuesta, setCodigoRespuesta] = useState(codigo !== '' ? codigo[0].codRespuesta : '');
    const [descripcion, setDescripcion] = useState(codigo !== '' ? codigo[0].dscCodRespuesta : '');
    const [alert, setAlert] = useState(null);
    const [form, setForm] = useState({ message: "" });
    const { post, put } = useCodigoService();
    const { getCodigos } = useCodigoRespuesta();

    let values = {};
    const addCodigos = async (values) => {
        const result = await post(values);
        return result;
    }
    const updateCodigos = async (idCodigo, values) => {
        const result = put(idCodigo, values);
        return result;
    }
    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (codigoRespuesta && descripcion) {
            values.dscCodRespuesta = descripcion;
            values.codRespuesta = codigoRespuesta;
            values.usuCreacion = "admin";
            values.indActivo = 1;
            if (render === 'Add') {

                const result = await addCodigos(values);
                if (result.data.code === 409) {
                    showAlert("error", "Error", result.data.msg);
                    return;
                } else {
                    await getCodigos();
                }
            } else {
                let idCodigo = codigo[0].idCodRespuesta;
                if (idCodigo !== undefined) {
                    const result = await updateCodigos(idCodigo, values);
                    if (result.data.code === 409) {
                        showAlert("error", "Error", result.data.msg);
                        return;
                    } else {
                        await getCodigos();
                    }
                }
            }
            onSave();

            setCodigoRespuesta('');
            setDescripcion('');
        } else {
            showAlert("error", "Error", errorMessages.FORM_ERROR);
        }
    };

    const handleChangeInput = async (event) => {
        const { value, maxLength } = event.target;

        if (value.length >= maxLength) {
            showAlert("warning", "Advertencia", warningMessages.MAX_LENGTH(maxLength));
        }

        setForm((prevForm) => ({
            ...prevForm,
            message: value.slice(0, maxLength),
        }));
    };

    const updateCodigoRespuesta = async (value) => {
        setCodigoRespuesta(value)
    }
    const updateDescripcion = async (value) => {
        setDescripcion(value)
    }

    return (
        { updateDescripcion, updateCodigoRespuesta, handleSubmit, handleChangeInput, form, codigoRespuesta, descripcion, alert }
    )
}