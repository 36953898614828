import { useState } from 'react';
import { useAgenciaService } from '../../../../service/agencia';
import { useAgenciaRecaudadores } from '../useAgenciaRecaudadores';
import { errorMessages, warningMessages } from '../../../../components/messages/commonMessages';

export const useFormulario = (onSave, onCancel, agencia, render) => {
    const [codAgencia, setCodAgencia] = useState(agencia !== '' ? agencia[0].codAgencia : '');
    const [dscAgencia, setDscAgencia] = useState(agencia !== '' ? agencia[0].dscAgencia : '');
    const [recaudador, setRecaudador] = useState(agencia !== '' ? agencia[0].recaudador : '');
    const [canal, setCanal] = useState(agencia !== '' ? agencia[0].canal : '');
    const [opcRecaudador, setOpcRecaudador] = useState([]);
    const [opcCanal, setOpcCanal] = useState([]);
    const [alert, setAlert] = useState(null);
    const [form, setForm] = useState({ message: "" });
    const [loading, setLoading] = useState(true);
    const { post, put, getRecaudadores, getCanales } = useAgenciaService();
    const { getAgencias } = useAgenciaRecaudadores();

    let values = {};
    const obtenerAgenciaRecaudadores = async () => {
        getRecaudadores().then((opcRecaudador) => {
            setTimeout(() => {
                setOpcRecaudador(opcRecaudador);
                setLoading(false);
            }, 1000);
        })
    }
    const obtenerAgenciaCanales = async () => {
        getCanales().then((opcCanal) => {
            setOpcCanal(opcCanal);
        })
    }
    const addAgenciaRecaudador = async (values) => {
        const result = await post(values);

        return result;
    }
    const updateAgenciaRecaudador = async (idAgencia, values) => {
        const result = put(idAgencia, values);

        return result;

    }

    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (dscAgencia && codAgencia && recaudador && canal) {
            values.dscAgencia = dscAgencia;
            values.codAgencia = codAgencia;
            values.usuCreacion = "maria.sanchez";
            values.recaudador = {
                idRecaudador: typeof recaudador === "object"
                    ? Number(recaudador.idRecaudador)
                    : Number(recaudador)
            };
            values.canal = {
                idCanal: typeof canal === "object"
                    ? Number(canal.idCanal)
                    : Number(canal)
            };
            values.indActivo = 1;
            if (render === 'Add') {
                const result = await addAgenciaRecaudador(values);
                if (result.data.code === 409) {
                    showAlert("error", "Error", result.data.msg);
                    return;
                } else {
                    await getAgencias();
                }

            } else {
                let idAgencia = agencia[0].idAgencia;
                if (idAgencia !== undefined) {
                    const result = await updateAgenciaRecaudador(idAgencia, values);
                    if (result.data.code === 409) {
                        showAlert("error", "Error", result.data.msg);
                        return;
                    } else {
                        await getAgencias();
                    }
                }
            }
            onSave();
            // Limpiar el formulario después de agregar el agencia
            setDscAgencia('');
            setRecaudador('');
            setCanal('');
            setCodAgencia('');
        } else {
            showAlert("warning", "Advertencia", errorMessages.FORM_ERROR);
        }
    };

    const handleChangeInput = async (event) => {
        const { value, maxLength } = event.target;

        if (value.length >= maxLength) {
            showAlert("warning", "Advertencia", warningMessages.MAX_LENGTH(maxLength));
        }

        setForm((prevForm) => ({
            ...prevForm,
            message: value.slice(0, maxLength),
        }));
    };

    const updateRecaudador = async (value) => {
        setRecaudador(value)
    }
    const updateCanal = async (value) => {
        setCanal(value)
    }
    const updateDscAgencia = async (value) => {
        setDscAgencia(value)
    }
    const updateCodAgencia = async (value) => {
        setCodAgencia(value)
    }
    return (
        {
            updateRecaudador, updateCanal, updateDscAgencia, handleSubmit, opcCanal, alert,
            updateCodAgencia, opcRecaudador, codAgencia, dscAgencia, recaudador, canal, loading,
            obtenerAgenciaRecaudadores, obtenerAgenciaCanales, handleChangeInput, form
        }
    )
};