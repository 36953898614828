import React, { useEffect } from 'react';
import { DataTable } from '../../Table/Table.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import { confirmationMessages } from '../../messages/commonMessages.jsx';
import { CustomDialog } from '../../messages/CustomDialog.jsx';
import '../../../App.css'
import { AlertDialog } from '../../messages/AlertDialog.jsx';
import { Alert } from '../../messages/Alert.jsx';
import Tooltip from '@mui/material/Tooltip';
import { Formulario } from './Formulario/Formulario.jsx';
import { useCodigoRespuesta } from './useCodigoRespuesta.js';
import Spinner from '../../spinner/LoadingSpinner.js';

const MantenimientoCodigoRespuesta = () => {

    const { handleRowSelectionChange, handleSave, handleCloseDialog, handleGenerateReport, handleFind, handleClean, 
        columnas, isDialogOpen, isEdit, isView, isAdd, alert, handleAdd, handleDeleteData, onChangeCodigo,
        loading, handleCancel, getCodigos, isDelete, selectedRow, data, codigo } = useCodigoRespuesta();

    useEffect(() => {
        if (codigo === "") {
            getCodigos();
        }
    }, [loading]);

    return (
        <div className='content-wrapper'>
            {loading && <Spinner />}
            {alert && (
                <Alert
                    severity={alert.severity}
                    title={alert.title}
                >
                    {alert.message}
                </Alert>
            )}
            <div className="wrapper-card">
                <h1>
                    Mantenimiento Códigos de Respuesta
                </h1>
                <div className='form-container'>
                    <div className="content">

                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Código Respuesta:
                                        </label>
                                        <Tooltip title="Código Respuesta">
                                            <input className="input-form" type="text" value={codigo} onChange={onChangeCodigo} name="codigo" placeholder="Código Respuesta" />
                                        </Tooltip>
                                    </div>
                                    <div className="button-filter">
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={handleFind}>Buscar</button>
                                        </Tooltip>
                                        <Tooltip title="Limpiar">
                                            <button className="action-button" onClick={handleClean}>Limpiar</button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Códigos de Respuesta
                            </div>
                            <div className="content">
                                <DataTable
                                    data={data && Array.isArray(data) ? data.map(row => ({ id: row.idCodRespuesta, ...row })) : []}
                                    columns={columnas}
                                    onRowSelectionChange={handleRowSelectionChange} >
                                    {data === null && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            Cargando datos...
                                        </div>
                                    )}
                                </DataTable>
                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Código de Respuesta'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} codigo={selectedRow} render={'Add'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Código de Respuesta'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} codigo={selectedRow} render={'Edit'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Código de Respuesta'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} codigo={selectedRow} render={'View'} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Código de Respuesta"}
                                            message={<> {confirmationMessages.DELETE_CONFIRMATION_CODIGO} <br /> <div style={{ marginLeft: '20%' }} > <b>Códigos de Respuesta:</b> {selectedRow[0].codRespuesta} </div> </>}
                                            isOpen={isDialogOpen}
                                            onClose={handleCloseDialog}
                                            onAccept={handleDeleteData}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoCodigoRespuesta };