import Tooltip from "@mui/material/Tooltip";
import { useFormulario } from "./useFormulario.js";
import { Alert } from "../../../messages/Alert.jsx";
import Spinner from "../../../spinner/LoadingSpinner.js";

export const Formulario = ({ onSave, onCancel, parametro, render }) => {
  const {
    onChangeDescripcion,
    onChangeValor,
    onChangeCategoria,
    handleSubmit,
    descripcion,
    valor,
    categoria,
    alertForm,
    loading,
  } = useFormulario(onSave, onCancel, parametro, render);
  return (
    <form onSubmit={handleSubmit}>
      {alertForm && (
        <Alert
          severity={alertForm.severity}
          title={alertForm.title}
          children={alertForm.children}
          isForm={true}
        />
      )}
      {loading && <Spinner />}
      <div className="fila-form">
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Categoría:
          </label>
          <Tooltip title="Categoría">
            <input
              id="input-categoria"
              className="input-form"
              type="text"
              placeholder="Categoría"
              value={categoria}
              onChange={onChangeCategoria}
              disabled={render === "View" ? true : null}
              pattern="[0-9]{1,10}"
              maxLength="10"
            />
          </Tooltip>
        </div>
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>Valor:</label>
          <Tooltip title="Valor">
            <input
              id="input-valor"
              className="input-form"
              type="text"
              placeholder="Valor"
              value={valor}
              onChange={onChangeValor}
              disabled={render === "View" ? true : null}
              maxLength="200"
            />
          </Tooltip>
        </div>
      </div>

      <div className="fila-form">
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Descripción:
          </label>
          <Tooltip title="Descripción">
            <input
              id="input-descripcion"
              className="input-form"
              type="text"
              placeholder="Descripción"
              value={descripcion}
              onChange={onChangeDescripcion}
              disabled={render === "View" ? true : null}
              maxLength="200"
            />
          </Tooltip>
        </div>
      </div>

      <div className="fila-form">
        <div className="button-form">
          {render !== "View" && (
            <Tooltip title="Aceptar">
              <button id="btn-accion" className="action-button">
                Aceptar
              </button>
            </Tooltip>
          )}
          <Tooltip title="Cancelar">
            <button
              id="btn-cancelar"
              className="action-button"
              onClick={onCancel}
            >
              Cancelar
            </button>
          </Tooltip>
        </div>
      </div>
    </form>
  );
};
