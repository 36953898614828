import React from "react";
import "./Alerts.css";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const getIcon = (severity) => {
  switch (severity) {
    case "error":
      return <ErrorIcon />;
    case "warning":
      return <WarningIcon />;
    case "info":
      return <InfoIcon />;
    case "success":
      return <CheckCircleIcon />;
    default:
      return null;
  }
};

const Alert = ({ severity, children, title, isForm = false }) => {
  return (
    <div className={`${isForm ? "alert-form" : "alert"} ${severity}`}>
      <div className="icon-container">
        {getIcon(severity)}
      </div>
      <div className={`${isForm ? "" : "title"}`}>
        <strong>{title}: &nbsp;</strong>
      </div>
      <div className={`${isForm ? "" : "title"}`}>
        {children}
      </div>
    </div>
  );
};

export { Alert };
