import React, { useState, useEffect } from 'react';
import { successMessages, errorMessages, warningMessages } from '../../messages/commonMessages.jsx';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { useCodigoService } from '../../../service/codigo.js';

export const useCodigoRespuesta = () => {
    const [selectedRow, setSelectedRow] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();
    const [alert, setAlert] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [codigo, setCodigo] = useState("");
    const { get, patch, getCodRespuesta } = useCodigoService();

    const getCodigos = async () => {
        get().then((data) => {
            if (Array.isArray(data)) {
                setData(data);
            } else {
                console.error("Error: Los datos no son un arreglo");
                showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
            }
        }).catch((error) => {
            showAlert("error", "Error", errorMessages.SERVER_ERROR);
        });
    }

    useEffect(() => {
        if (data?.length > 0) {
            setTimeout(async () => {
                setLoading(false);
            }, 1000);
        } else {
            setTimeout(async () => {
                setLoading(false);
            }, 1000);
        }
    }, [data]);

    const deleteCodigos = async (idCodigo, values) => {
        patch(idCodigo, values);
    }

    const columnas = [
        { field: 'dscCodRespuesta', headerName: 'Descripción', flex: 1, },
        { field: 'codRespuesta', headerName: 'Código Respuesta', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleSave = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setLoading(true);
        getCodigos();
        showAlert("success", "Éxito", successMessages.CHANGES_SAVED);
    };

    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleConfirmationDelete = () => {
        handleCloseDialog();
        setIsDelete(false);
        showAlert("success", "Éxito", successMessages.DELETE_SUCCESSFUL);
    };

    const handleDeleteData = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsDelete(false);
        setLoading(true);
        deleteCodigos(selectedRow[0].idCodRespuesta, selectedRow)
            .then(() => {
                return getCodigos();
            })
            .catch((error) => {
                console.error("Error al eliminar o recargar datos:", error);
                showAlert("error", "Error", errorMessages.DELETE_ERROR);
            });
        getCodigos();
        showAlert("success", "Éxito", successMessages.DELETE_SUCCESSFUL);
    };

    const handleGenerateReport = () => {
        showAlert("success", "Éxito", successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleFind = async () => {
        try {
            if (codigo !== "") {
                setLoading(true);
                getCodRespuesta(codigo).then((data) => {
                    if (Array.isArray(data)) {
                        setData(data);
                    } else {
                        console.error("Error: Los datos no son un arreglo");
                        showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
                    }
                }).catch((error) => {
                    showAlert("error", "Error", errorMessages.SERVER_ERROR);
                });
            } else {
                showAlert("warning", "Advertencia", warningMessages.WARNING_DATA_FIND);
            }

        } catch (error) {
            showAlert("warning", "Advertencia", warningMessages.DATA_NOT_FOUND);
        }
    };

    const handleClean = () => {
        setCodigo("");
        setLoading(true);
        getCodigos();
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRows = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRow(selectedRows);
        if (selectedRows.length > 0) {
            console.log(selectedRows[0].dscCodRespuesta);
        }
    };

    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };

    const onChangeCodigo = async ({ target }) => {
        const { value } = target;
        setCodigo(value);
    };

    return (
        {
            handleRowSelectionChange, handleSave, handleCloseDialog, handleGenerateReport, alert, handleFind,
            handleConfirmationDelete, columnas, isDialogOpen, isEdit, isView, isAdd, handleAdd, onChangeCodigo,
            handleClean, handleDeleteData, handleCancel, getCodigos, isDelete, selectedRow, data, loading, codigo
        }
    )
}