import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";
import { errorMessages } from "../components/messages/commonMessages";

export const useLlaveService = () => {
  const { serverData } = useConectividadApi();
  const session = useSelector((state) => state.session);

  /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de Llaves de Consulta
   * @param        Valores de busqueda opcionales
   */
  const getLlavesConsulta = async () => {
    try {
      const options = {
        method: "GET",
        url: `/llave`,
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
      };
      return await serverData(options).then((response) => {
        let data = response;
        if (data) {
          return data;
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de Llaves de Consulta aplicando filtros
   * @param tipoBusqueda  Tipo de busqueda
   * @param tipoLlave     Tipo de llave
   * @param descripcion   Descripcion de la llave
   */
  const getLlavesConsultaBusqueda = async (
    tipoBusqueda = "",
    tipoLlave = "",
    descripcion = ""
  ) => {
    try {
      const options = {
        method: "GET",
        url: `/llave/busqueda?tipoBusqueda=${tipoBusqueda}&tipoLlave=${tipoLlave}&descripcion=${descripcion}`,
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
      };
      return await serverData(options).then((response) => {
        let data = response;
        if (data) {
          return data;
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @methodApi post.
   * @description  Método encargado de crear llave de consulta
   */
  const post = async (values) => {
    let result = {};
    try {
      const options = {
        method: "POST",
        url: "/llave",
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
        data: values,
      };
      try {
        let resultServer = await serverData(options);

        if (resultServer !== undefined) {
          if (resultServer?.code !== 409) {
            result = { success: true, message: "", data: {} };
          } else {
            result = { success: false, message: resultServer?.msg, data: [] };
          }
        } else {
          result = {
            success: false,
            message: errorMessages.SERVER_ERROR,
            data: [],
          };
        }
      } catch (error) {
        let { data } = error;
        result = { success: false, message: data.msg, data };
      }
    } catch (error) {
      let { data } = error;
      result = { success: false, message: data.msg, data };
    }
    return result;
  };

  /**
   * @methodApi v.
   * @description  Método encargado de actualizar llave de consulta
   */
  const put = async (idLlave, values) => {
    let result = {};
    try {
      const options = {
        method: "PUT",
        url: `/llave/${idLlave}`,
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
        data: values,
      };
      try {
        let resultServer = await serverData(options);

        if (resultServer !== undefined) {
          if (resultServer?.code !== 409) {
            result = { success: true, message: "", data: {} };
          } else {
            result = { success: false, message: resultServer?.msg, data: [] };
          }
        } else {
          result = {
            success: false,
            message: errorMessages.SERVER_ERROR,
            data: [],
          };
        }
      } catch (error) {
        let { data } = error;
        result = { success: false, message: data.msg, data };
      }
    } catch (error) {
      let { data } = error;
      result = { success: false, message: data.msg, data };
    }
    return result;
  };

  /**
   * @methodApi v.
   * @description  Método encargado de actualizar parcialmente(estado) llave de consulta
   */
  const patch = async (idLlave) => {
    let result = {};
    try {
      const options = {
        method: "PATCH",
        url: `/llave/${idLlave}`,
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
      };
      try {
        let resultServer = await serverData(options);

        if (resultServer !== undefined) {
          if (resultServer?.code !== 409) {
            result = { success: true, message: "", data: {} };
          } else {
            result = { success: false, message: resultServer?.msg, data: [] };
          }
        } else {
          result = {
            success: false,
            message: errorMessages.SERVER_ERROR,
            data: [],
          };
        }
      } catch (error) {
        let { data } = error;
        result = { success: false, message: data.msg, data };
      }
    } catch (error) {
      let { data } = error;
      result = { success: false, message: data.msg, data };
    }
    return result;
  };

  return {
    //Api llaves consulta
    getLlavesConsulta,
    getLlavesConsultaBusqueda,
    post,
    put,
    patch,
  };
};
