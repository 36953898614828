import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";
import { errorMessages } from "../components/messages/commonMessages";

export const useParametrosService = () => {
  const { serverData } = useConectividadApi();
  const session = useSelector((state) => state.session);

  /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de parametros
   */
  const get = async () => {
    try {
      const options = {
        method: "GET",
        url: "/parametros",
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
      };
      return await serverData(options).then((response) => {
        let data = response;
        if (data) {
          return data;
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de parametros
   * @param descripcion  Descripcion del parametro
   * @param valor        Valor del parametro
   * @param categoria    Categoria del parametro
   */
  const getParametrosBusqueda = async (
    descripcion = "",
    valor = "",
    categoria = ""
  ) => {
    try {
      const options = {
        method: "GET",
        url: `/parametros/busqueda?descripcion=${descripcion}&valor=${valor}&categoria=${categoria}`,
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
      };
      return await serverData(options).then((response) => {
        let data = response;
        if (data) {
          return data;
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @methodApi post.
   * @description  Método encargado de crear parametros
   */
  const post = async (values) => {
    let result = {};
    try {
      const options = {
        method: "POST",
        url: "/parametros",
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
        data: values,
      };
      try {
        let resultServer = await serverData(options);

        if (resultServer !== undefined) {
          if (resultServer?.code !== 409) {
            result = { success: true, message: "", data: {} };
          } else {
            result = { success: false, message: resultServer?.msg, data: [] };
          }
        } else {
          result = {
            success: false,
            message: errorMessages.SERVER_ERROR,
            data: [],
          };
        }
      } catch (error) {
        let { data } = error;
        result = { success: false, message: data.msg, data };
      }
    } catch (error) {
      let { data } = error;
      result = { success: false, message: data.msg, data };
    }
    return result;
  };

  /**
   * @methodApi v.
   * @description  Método encargado de actualizar parametros
   */
  const put = async (idParametro, values) => {
    let result = {};
    try {
      const options = {
        method: "PUT",
        url: `/parametros/${idParametro}`,
        headers: {
          Authorization: `Bearer ${session.credentials.accessToken}`,
        },
        data: values,
      };
      try {
        let resultServer = await serverData(options);

        if (resultServer !== undefined) {
          if (resultServer?.code !== 409) {
            result = { success: true, message: "", data: {} };
          } else {
            result = { success: false, message: resultServer?.msg, data: [] };
          }
        } else {
          result = {
            success: false,
            message: errorMessages.SERVER_ERROR,
            data: [],
          };
        }
      } catch (error) {
        let { data } = error;
        result = { success: false, message: data.msg, data };
      }
    } catch (error) {
      let { data } = error;
      result = { success: false, message: data.msg, data };
    }
    return result;
  };

  return {
    //Api Parametros
    get,
    getParametrosBusqueda,
    post,
    put,
  };
};
