import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import { successMessages, warningMessages, errorMessages } from '../../messages/commonMessages.jsx';
import { useAgenciaService } from '../../../service/agencia.js';

export const useAgenciaRecaudadores = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [data, setData] = useState([]);
    const [opciones, setOpciones] = useState([]);
    const [alert, setAlert] = useState(null);
    const [recaudador, setRecaudador] = useState(null);
    const [loading, setLoading] = useState(false);
    const { patch, getRecaudadores, getAgenciasRecaudadores } = useAgenciaService();

    const getAgencias = async () => {
        if (!recaudador || isNaN(Number(recaudador))) {
            return;
        }

        getAgenciasRecaudadores(Number(recaudador))
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                console.error("Error al obtener agencias:", error);
            });
    }

    const getobtenerRecaudadores = async () => {
        getRecaudadores().then((opciones) => {
            if (Array.isArray(opciones)) {
                setOpciones(opciones);
            } else {
                console.error("Error: Los datos no son un arreglo");
                showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
            }
        }).catch((error) => {
            showAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
        });
    }

    const disableAgencia = async (idCanal) => {
        patch(idCanal).then(() => {
            return getAgencias();
        });
    }

    useEffect(() => {
        getobtenerRecaudadores();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            setTimeout(async () => {
                setLoading(false);
            }, 1000);
        }
    }, [data]);

    const handleSave = async () => {
        try {
            handleCloseDialog();
            setIsEdit(false);
            setIsAdd(false);
            setLoading(true);

            await getAgencias();

            showAlert("success", "Éxito", successMessages.CHANGES_SAVED);
        } catch (error) {
            console.error("Error al actualizar datos:", error);
            showAlert("error", "Error", errorMessages.UNEXPECTED_ERROR);
        }
    };
    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };
    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };
    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleGenerateReport = () => {
        showAlert("success", "Éxito", successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleFind = async () => {
        try {
            if (recaudador !== null) {
                setLoading(true);
                getAgencias();
            } else {
                showAlert("warning", "Advertencia", warningMessages.WARNING_DATA_FIND);
            }

        } catch (error) {
            showAlert("warning", "Advertencia", warningMessages.DATA_NOT_FOUND);
        }
    };

    const handleDeleteData = async () => {
        try {
            handleCloseDialog();
            setIsEdit(false);
            setIsAdd(false);
            setIsDelete(false);
            setLoading(true);

            disableAgencia(selectedRow[0].idAgencia);
            getAgencias();

            showAlert("success", "Éxito", successMessages.DELETE_SUCCESSFUL);
        } catch (error) {
            console.error("Error al eliminar o recargar datos:", error);
            showAlert("error", "Error", errorMessages.DELETE_ERROR);
        }
    };

    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRow = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRow(selectedRow);
        if (selectedRow.length > 0) {
            console.log(selectedRow[0].dscAgencia);
            console.log(selectedRow[0]);
        }
    };
    const columnas = [
        { field: 'dscAgencia', headerName: 'Descripción', flex: 1 },
        { field: 'codAgencia', headerName: 'Código Agencia', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button id='btn-consultar' className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button id='btn-editar' className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];
    return (
        {
            handleRowSelectionChange, handleSave, handleCloseDialog, handleFind, recaudador, getAgencias,
            getobtenerRecaudadores, handleDeleteData, columnas, isDialogOpen, isEdit, isView, isAdd, setRecaudador,
            handleGenerateReport, handleAdd, handleCancel, isDelete, selectedRow, data, opciones, alert, loading
        }
    )
};