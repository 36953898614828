import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { successMessages, errorMessages } from '../../messages/commonMessages.jsx';
import { useDetalleComisionService } from '../../../service/detalleComision.js';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

export const useDetalleComisiones = () => {
    const [isSubDialogDialogOpen, setIsSubDialogDialogOpen] = useState(false);
    const [selectedSubRow, setSelectedSubRow] = useState(null);
    const [selectedIdRow, setSelectedIdRow] = useState(null);
    const [isSubAdd, setIsSubAdd] = useState();
    const [isSubEdit, setIsSubEdit] = useState();
    const [isSubView, setIsSubView] = useState();
    const [isSubDelete, setIsSubDelete] = useState();
    const [detalle, setDetalle] = useState([]);
    const [subAlert, setSubAlert] = useState(null);
    const { getComisionTipo, patch } = useDetalleComisionService();

    const getDetallesComisiones = async (idComision) => {
        getComisionTipo(idComision).then((response) => {
            if (Array.isArray(response)) {
                setDetalle(response);
            } else {
                setDetalle([]);
                console.error("Error: Los datos no son un arreglo");
                showSubAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
            }
        }).catch((error) => {
            console.error("Error al obtener los detalle comisiones:", error);
            showSubAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
        });
    }

    const deleteDetallComisiones = async (idComision, values) => {
        patch(idComision, values);
    }

    const handleSelectedIdRow = (selectedIdRow) => {
        setSelectedIdRow(selectedIdRow);
    }

    const handleSubSave = () => {
        handleSubCloseDialog();
        setIsSubEdit(false);
        setIsSubAdd(false);
        if (Array.isArray(selectedSubRow) && selectedSubRow.length > 0 && selectedSubRow[0]?.idComision !== undefined) {
            getDetallesComisiones(selectedSubRow[0].idComision);
        } else {
            getDetallesComisiones(selectedIdRow);
        }
        showSubAlert("success", "Éxito", successMessages.CHANGES_SAVED);
    };

    const handleSubAdd = () => {
        setIsSubDialogDialogOpen(true);
        setIsSubAdd(true);
        setSelectedSubRow('');
    };

    const handleSubCloseDialog = () => {
        setIsSubDialogDialogOpen(false);
        setIsSubEdit(false);
        setIsSubAdd(false);
        setIsSubView(false);
    };

    const handleSubCancel = () => {
        handleSubCloseDialog();
        setIsSubEdit(false);
        setIsSubAdd(false);
        setIsSubView(false);
        setIsSubDelete(false);
    };

    const handleSubDelete = () => {
        setIsSubDialogDialogOpen(true);
        setIsSubDelete(true);
    };

    const handleSubConsult = () => {
        setIsSubDialogDialogOpen(true);
        setIsSubView(true);
    };

    const handleSubEdit = () => {
        setIsSubDialogDialogOpen(true);
        setIsSubEdit(true);
    };

    const handleDeleteDetalle = () => {
        handleSubCloseDialog();
        setIsSubEdit(false);
        setIsSubAdd(false);
        setIsSubDelete(false);
        deleteDetallComisiones(selectedSubRow[0].idComisionTip, selectedSubRow)
            .then(() => {
                return getDetallesComisiones(selectedSubRow[0].idComision);
            })
            .catch((error) => {
                console.error("Error al eliminar o recargar datos:", error);
                showSubAlert("error", "Error", errorMessages.DELETE_ERROR);
            });
        getDetallesComisiones(selectedSubRow[0].idComision);
        showSubAlert("success", "Éxito", successMessages.DELETE_SUCCESSFUL);
    };

    const handleSubRowSelectionChange = (selectedSubRowIds, detalle) => {
        const selectedIDs = new Set(selectedSubRowIds);
        const selectedSubRow = detalle.filter((subRow) =>
            selectedIDs.has(subRow.idComisionTip),
        );
        setSelectedSubRow(selectedSubRow);
        if (selectedSubRow.length > 0) {
            console.log(selectedSubRow[0].idComisionTip);
            console.log(selectedSubRow[0]);
        }
    };

    const showSubAlert = (severity, title, message) => {
        setSubAlert({ severity, title, message });
        setTimeout(() => {
            setSubAlert(null);
        }, 3000);
    };

    const subColumns = [
        { id: 'lmtInferior', label: 'Limite Inferior' },
        { id: 'lmtSuperior', label: 'Limite Superior' },
        { id: 'mtoComision', label: 'Monto Comisión' },
        {
            id: 'accion', label: 'Acciones',
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="action-button" onClick={handleSubConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Editar">
                        <button className="action-button" onClick={handleSubEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="action-button" onClick={handleSubDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>

                </div>
            ),
        },
    ];

    return (
        {
            handleSubRowSelectionChange, getDetallesComisiones, handleSubSave, handleSubCloseDialog, subColumns,
            isSubDialogDialogOpen, isSubEdit, isSubView, isSubAdd, subAlert, handleSubAdd, handleDeleteDetalle, handleSubCancel,
            isSubDelete, handleSelectedIdRow, selectedSubRow, detalle
        }
    )
};