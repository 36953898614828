import '../../../../App.css'
import Tooltip from '@mui/material/Tooltip';
import { useFormulario } from './useFormulario.js';

const Formulario = ({ onSave, onCancel, canal, render }) => {
    const { updateDescripcion, updateNombre, handleSubmit, updateIdComision, opciones,
        descripcion, nombre, idComision } = useFormulario(onSave, onCancel, canal, render);
    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Nombre Canal
                    </label>
                    <Tooltip title="Nombre Canal">
                        <input className='input-form' type="text" placeholder='Nombre Canal' value={nombre} onChange={(e) => updateNombre(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Descripción
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' type="text" placeholder='Descripción' value={descripcion} onChange={(e) => updateDescripcion(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>

            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Comisión
                    </label>
                    <Tooltip title="Comisión">
                        <select className='select-form' value={idComision} onChange={(e) => updateIdComision(e.target.value)} disabled={render === 'View' ? true : null} >
                            <option value="">Seleccione la comisión</option>
                            {opciones.map((opcion) => (
                                <option key={opcion.idComision} value={opcion.idComision}>
                                    {opcion.dscComision}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
            </div>

            <div className="fila-form">
                <div className="button-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { Formulario };