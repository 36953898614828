export const successMessages = {
  OPERATION_SUCCESS: "La operación se completó con éxito.",
  CHANGES_SAVED: "Los cambios se guardaron correctamente.",
  SUCCESSFUL_OPERATION: "¡Operación exitosa!",
  UPDATE_SUCCESSFUL: "La información se actualizó correctamente.",
  DELETE_SUCCESSFUL: "La información se elimino correctamente.",
  DOWNLOAD_SUCCESSFUL: "La descarga se ha completado satisfactoriamente.",
  CHANGES_SAVED_SUCCESS: (mantenimiento) => `¡La información se guardo correctamente en ${mantenimiento}!`,
  };
  
  export const errorMessages = {
    UNEXPECTED_ERROR: 'Se produjo un error inesperado.',
    ERROR_LOADING_DATA: 'Error al cargar los datos.',
    REQUEST_NOT_COMPLETED: 'La solicitud no se pudo completar.',
    OPERATION_PROBLEM: 'Hubo un problema al procesar la operación.',
    CONNECTION_ERROR: 'No se puede conectar al servidor. Verifique su conexión a Internet e inténtelo de nuevo.',
    AUTHENTICATION_ERROR: 'Credenciales inválidas. Por favor, ingrese información de inicio de sesión válida.',
    FORM_ERROR: 'Por favor, complete todos los campos obligatorios (*) correctamente.',
    ACCESS_ERROR: 'No tiene permisos suficientes para acceder a esta función.',
    RESOURCE_NOT_FOUND: 'El recurso solicitado no se encuentra.',
    INVALID_ENTRANCE_ERROR: (tipo) => `Por favor, ingrese una entrada válida. Valor aceptado tipo ${tipo}`,
    SERVER_ERROR: 'Ocurrió un error interno del servidor. Por favor, inténtelo más tarde.',
    TIMEOUT_ERROR: 'La solicitud ha excedido el tiempo de espera. Inténtelo de nuevo.',
    UPDATE_ERROR: 'No se pudo actualizar la información. Inténtelo de nuevo.',
    SESSION_EXPIRED_ERROR: 'Su sesión ha caducado. Vuelva a iniciar sesión para continuar.',
    DELETE_ERROR: 'Ocurrió un error al intentar eliminar. Inténtelo de nuevo.',
  };
  
  export const warningMessages = {
    PLEASE_NOTE: 'Ten en cuenta que...',
    WARNING_PROBLEMS: 'Se detectaron algunos problemas.',
    WARNING_DATA_FIND: 'No se ha seleccionado ningún dato para buscar.',
    REVIEW_ENTERED_INFO: 'Es posible que desees revisar la información ingresada.',
    CONFIRM_DELETE: '¿Estás seguro/a de que deseas eliminar este elemento? Esta acción no se puede deshacer.',
    UNSTABLE_CONNECTION: 'La conexión a Internet parece estar inestable. Verifica tu conexión.',
    INCOMPLETE_FIELDS: 'Algunos campos obligatorios no se han completado. Por favor, revisa tu entrada.',
    LOGOUT_WARNING: 'Estás a punto de cerrar sesión. ¿Estás seguro/a de que deseas continuar?',
    WEAK_PASSWORD: 'La contraseña es débil. Se recomienda una contraseña más segura.',
    UNSAVED_CHANGES: 'Los cambios no guardados se perderán. ¿Deseas continuar?',
    IRREVERSIBLE_OPERATION: 'Esta operación es irreversible. Por favor, confirma antes de proceder.',
    SECURITY_ALERT: 'Se ha detectado una actividad sospechosa en tu cuenta. Por favor, revisa tu seguridad.',
    NO_PERMISSION: 'No tienes permisos para realizar esta acción.',
    DATA_NOT_FOUND: 'No se encontraron datos con ese recaudador',
    MAX_LENGTH: (max) => `Has alcanzado el número máximo de ${max} caracteres.`,
    MAX_DECIMAL: (max) => `Has alcanzado el máximo de ${max} decimales.`,
  };

export const infoMessages = {
  GENERAL_INFO: "Información general.",
  ADDITIONAL_INFO: "Información adicional.",
  INFO_NOTICE: "Aviso informativo.",
};

export const confirmationMessages = {
  CONFIRM_ACTION: "¿Estás seguro de realizar esta acción?",
  DELETE_CONFIRMATION: "¿Estás seguro de eliminar este elemento?",
  DELETE_CONFIRMATION_CONVENIO: "¿Está seguro que desea eliminar el convenio?",
  DELETE_CONFIRMATION_PARAMETRO:
    "¿Está seguro que desea eliminar el parámetro?",
  DELETE_CONFIRMATION_CANAL: "¿Está seguro que desea inactivar el canal? ",
  DELETE_CONFIRMATION_AGENCIA: "¿Está seguro que desea eliminar la agencia?",
  DELETE_CONFIRMATION_COMISION: "¿Está seguro que desea eliminar la comisión?",
  DELETE_CONFIRMATION_DETALLE_COMISION: "¿Está seguro que desea eliminar el detalle comisión?",
  DELETE_CONFIRMATION_CODIGO: "¿Está seguro que desea eliminar el código?",
  DELETE_CONFIRMATION_OFICINA: "¿Está seguro que desea eliminar la oficina?",
  DELETE_CONFIRMATION_LLAVECONSULTA:
    "¿Está seguro que desea eliminar la llave de consulta?",
  PROCEED_CONFIRMATION: "¿Deseas continuar con esta operación?",
  CONFIRM_SAVE_CHANGES: "¿Quieres guardar los cambios realizados?",
  CONFIRM_CANCEL: "¿Estás seguro de cancelar?",
};

export const actionMessages = {
  CHANGES_ACTION: "Si acepta puede hacer cambios que afecten la pantalla.",
  CONFIRMATION_CHANGES:
    "Al aceptar, se guardarán los cambios y afectarán la configuración de la aplicación.",
  CHANGES_NOT_SAVE: "Tiene cambios no guardados.",
  SESION_SAVE_CHANGES:
    "Si cierra sesión, perderá todas las actividades no guardadas.",
  SESION_EXPIRED:
    "Su sesión está a punto de caducar. Guarde cualquier cambio antes de continuar.",
};

export const title = {
  CONSULT: (consult) => `Consultar ${consult}!`,
  ADD: (add) => `Agregar ${add}!`,
  EDIT: (edit) => `Editar ${edit}!`,
};
