import React, { useEffect } from "react";
import { DataTable } from "../../Table/Table.jsx";
import { Footer } from "../../Footer/Footer.jsx";
import { CustomDialog } from "../../messages/CustomDialog.jsx";
import "../../../App.css";
import {
  successMessages,
  confirmationMessages,
} from "../../messages/commonMessages.jsx";
import Tooltip from "@mui/material/Tooltip";
import { AlertDialog } from "../../messages/AlertDialog.jsx";
import { Formulario } from "./Formulario/Formulario.jsx";
import { useParametros } from "./useParametros.js";
import { Alert } from "../../messages/Alert.jsx";
import Spinner from "../../spinner/LoadingSpinner.js";

const MantenimientoParametro = () => {
  const {
    handleRowSelectionChange,
    handleParametroSearch,
    handleGenerateReport,
    handleSave,
    handleAdd,
    handleCancel,
    handleCloseDialog,
    handleClean,
    onChangeDescripcion,
    onChangeValor,
    onChangeCategoria,
    getParametros,
    isDialogOpen,
    isEdit,
    isView,
    isAdd,
    loading,
    selectedRow,
    data,
    columnas,
    descripcion,
    valor,
    categoria,
    alert,
  } = useParametros();

  useEffect(() => {
    getParametros();
  }, []);

  return (
    <div className="content-wrapper">
      {alert && (
        <Alert
          severity={alert.severity}
          title={alert.title}
          children={alert.children}
        />
      )}
      {loading && <Spinner />}
      <div className="wrapper-card">
        <h1>Mantenimiento de Parámetros</h1>
        <div className="form-container">
          <div className="content">
            <div className="form-container">
              <div className="title">Parámetros</div>
              <div className="content-filter">
                <div className="fila-form">
                  <div className="columna-form">
                    <label>Descripcion:</label>
                    <Tooltip title="Tipo de Búsqueda">
                      <input
                        className="input-form"
                        type="text"
                        value={descripcion}
                        onChange={onChangeDescripcion}
                        name="descripcion"
                        placeholder="Descripción"
                      />
                    </Tooltip>
                  </div>
                  <div className="columna-form">
                    <label>Valor:</label>
                    <Tooltip title="Valor">
                      <input
                        className="input-form"
                        type="text"
                        value={valor}
                        onChange={onChangeValor}
                        name="valor"
                        placeholder="Valor"
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="fila-form">
                  <div className="columna-form">
                    <label>Categoría:</label>
                    <Tooltip title="Categoría">
                      <input
                        className="input-form"
                        type="text"
                        value={categoria}
                        onChange={onChangeCategoria}
                        name="categoria"
                        placeholder="Categoría"
                      />
                    </Tooltip>
                  </div>
                  <div className="columna-form"></div>
                </div>
                <div className="fila-form">
                  <div className="button-filter" style={{ textAlign: "right" }}>
                    <Tooltip title="Limpiar">
                      <button className="action-button" onClick={handleClean}>
                        Limpiar
                      </button>
                    </Tooltip>
                    <Tooltip title="Buscar">
                      <button
                        className="action-button"
                        onClick={handleParametroSearch}
                      >
                        Buscar
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <div className="title">Lista de Parámetros</div>
              <div className="content">
                <DataTable
                  data={
                    data && Array.isArray(data)
                      ? data.map((row) => ({ id: row.idParametro, ...row }))
                      : []
                  }
                  columns={columnas}
                  onRowSelectionChange={handleRowSelectionChange}
                ></DataTable>
                <div className="button-action">
                  <Tooltip title="Agregar">
                    <button
                      id="btn-agregar-dialog"
                      className="action-button"
                      onClick={handleAdd}
                    >
                      Agregar
                    </button>
                  </Tooltip>

                  {isDialogOpen && isAdd && (
                    <CustomDialog
                      question={"Agregar Parámetro"}
                      message={
                        <Formulario
                          onSave={handleSave}
                          onCancel={handleCancel}
                          parametro={selectedRow}
                          render={"Add"}
                        />
                      }
                      onClose={handleCloseDialog}
                    />
                  )}

                  {isDialogOpen && isEdit && (
                    <CustomDialog
                      question={"Modificar Párametro"}
                      message={
                        <Formulario
                          onSave={handleSave}
                          onCancel={handleCancel}
                          parametro={selectedRow}
                          render={"Edit"}
                        />
                      }
                      onClose={handleCloseDialog}
                    />
                  )}

                  {isDialogOpen && isView && (
                    <CustomDialog
                      question={"Consultar Parámetro"}
                      message={
                        <Formulario
                          onSave={handleSave}
                          onCancel={handleCancel}
                          parametro={selectedRow}
                          render={"View"}
                        />
                      }
                      onClose={handleCloseDialog}
                    />
                  )}

                  <Tooltip title="Generar Reporte">
                    <button
                      id="btn-generar-reporte"
                      className="action-button"
                      onClick={handleGenerateReport}
                    >
                      Generar Reporte
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { MantenimientoParametro };
