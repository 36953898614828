import { useState, useEffect } from 'react';
import { useCanalesService } from '../../../../service/canal';
import { useCanal } from '../useCanal';
import { errorMessages } from '../../../../components/messages/commonMessages';


export const useFormulario = (onSave, onCancel, canal, render) => {
    //cnsCanal - nomCanal -dscCanal
    const [nombre, setNombre] = useState(canal !== '' ? canal[0].nomCanal : '');
    const [descripcion, setDescripcion] = useState(canal !== '' ? canal[0].dscCanal : '');
    const [idComision, setIdComision] = useState(canal !== '' ? canal[0].idComision : '');
    const [opciones, setOpciones] = useState([]);
    const { post, put, getComisiones } = useCanalesService();
    const { getCanales } = useCanal();

    let values = {};
    const obtenerComisiones = async () => {
        getComisiones().then((opciones) => {
            setOpciones(opciones);
        })
    }
    useEffect(() => {
        obtenerComisiones();
    }, []);
    const addCanal = async (values) => {
        post(values);
    }
    const updateCanal = async (idCanal, values) => {
        put(idCanal, values);
    }
    const handleSubmit = async (e) => {
        console.log('Aquí handleSubmit');
        e.preventDefault();
        if (nombre && descripcion) {
            values.nomCanal = nombre;
            values.dscCanal = descripcion;
            values.idComision = idComision;
            values.usuCreacion = "allan.najera"; //Hay que tomarlo del state de redux
            if (render === 'Add') {
                await addCanal(values);//Agregar
                await getCanales();

            } else {
                let idCanal = canal[0].idCanal
                if (idCanal !== undefined) {
                    await updateCanal(idCanal, values); //Modificar
                    await getCanales();
                }
            }
            onSave();
            // Limpiar el formulario después de agregar el canal
            setNombre('');
            setDescripcion('');
            setIdComision('');
        } else {
            alert(errorMessages.FORM_ERROR);
        }
    };


    const updateNombre = async (value) => {
        setNombre(value)
    }
    const updateDescripcion = async (value) => {
        setDescripcion(value)
    }
    const updateIdComision = async (value) => {
        setIdComision(value)
    }
    return (
        { updateDescripcion, updateNombre, updateIdComision, handleSubmit, opciones, nombre, descripcion, idComision }
    )
};