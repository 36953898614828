import { useState } from "react";
import { useOficinaService } from "../../../../service/oficina";
import { useOficina } from "../useOficina";
import {
  errorMessages,
  warningMessages,
} from "../../../../components/messages/commonMessages";

export const useFormulario = (onSave, selectedRow, render) => {
  const [tipoFacturador, setTipoFacturador] = useState(
    selectedRow !== "" ? selectedRow[0].facturador.idFacturador : ""
  );
  const [nombre, setNombre] = useState(
    selectedRow !== "" ? selectedRow[0].nombre : ""
  );
  const [codigoInterno, setCodigoInterno] = useState(
    selectedRow !== "" ? selectedRow[0].codInterno : ""
  );
  const [alertForm, setAlertForm] = useState(null);

  const [loading, setLoading] = useState(false);

  const { post, put } = useOficinaService();

  let values = {};

  const addOficina = async (values) => {
    return await post(values);
  };
  const updateOficina = async (idOficina, values) => {
    return await put(idOficina, values);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result;
    if (codigoInterno && tipoFacturador && nombre) {
      setLoading(true);
      values.codInterno = codigoInterno;
      values.nomOficina = nombre;
      values.facturador = {};
      values.facturador.idFacturador = tipoFacturador;

      if (render === "Add") {
        result = await addOficina(values);
      } else {
        let idOficina = selectedRow[0].idOficina;
        if (idOficina !== undefined) {
          result = await updateOficina(idOficina, values);
        }
      }

      setLoading(false);

      if (result !== undefined) {
        if (result?.success) {
          onSave();
          setNombre("");
          setTipoFacturador("");
          setCodigoInterno("");
        } else {
          if (result.message !== errorMessages.SERVER_ERROR) {
            showAlert("warning", "Advertencia", result.message);
          } else {
            showAlert("error", "Error", result.message);
          }
        }
      } else {
        showAlert("error", "Error", errorMessages.SERVER_ERROR);
      }
    } else {
      showAlert("error", "Error", errorMessages.FORM_ERROR);
    }
  };

  const showAlert = (severity, title, children) => {
    setAlertForm({ severity, title, children });

    setTimeout(() => {
      setAlertForm(null);
    }, 5000);
  };

  const onChangeNombre = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setNombre(value);
    }
  };

  const onChangeFacturador = async ({ target }) => {
    const { value } = target;
    setTipoFacturador(value);
  };

  const onChangeCodigoInterno = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setCodigoInterno(value);
    }
  };

  return {
    onChangeNombre,
    onChangeFacturador,
    onChangeCodigoInterno,
    handleSubmit,
    tipoFacturador,
    nombre,
    codigoInterno,
    alertForm,
    loading,
  };
};
