import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useFormulario } from './useFormulario.js';
import { Alert } from '../../../messages/Alert'

const Formulario = ({ onSave, onCancel, codigo, render }) => {
    const { updateDescripcion, updateCodigoRespuesta, handleSubmit, codigoRespuesta, 
        descripcion, alert, handleChangeInput } = useFormulario(onSave, onCancel, codigo, render);

    return (
        <form onSubmit={handleSubmit}>
            {alert && (
                <Alert
                    severity={alert.severity}
                    title={alert.title}
                    isForm={true}
                >
                    {alert.message}
                </Alert>
            )}
            <div className="fila-form">
                <div className="columna-form">
                    <label className={render !== "View" ? "required" : ""}>
                        Código Respuesta:
                    </label>
                    <Tooltip title="Código Respuesta">
                        <input className='input-form' placeholder='Código Respuesta' type="text" maxLength="100" value={codigoRespuesta} onChange={(e) => { handleChangeInput(e); updateCodigoRespuesta(e.target.value)}} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label className={render !== "View" ? "required" : ""}>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' placeholder='Descripción' type="text" maxLength="100" value={descripcion} onChange={(e) => { handleChangeInput(e);  updateDescripcion(e.target.value)}} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button id="btn-accion" className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { Formulario };