import React, { useEffect } from 'react';
import { useFormulario } from './useFormulario.js';
import Tooltip from '@mui/material/Tooltip';
import { Alert } from '../../../messages/Alert'
import Spinner from '../../../spinner/LoadingSpinner.js';

const Formulario = ({ onSave, onCancel, agencia, render }) => {

    const { updateRecaudador, updateCanal, updateDscAgencia, handleSubmit, opcCanal, alert, loading,
        obtenerAgenciaRecaudadores, obtenerAgenciaCanales, handleChangeInput, updateCodAgencia, 
        opcRecaudador, codAgencia, dscAgencia, recaudador, canal } = useFormulario(onSave, onCancel, agencia, render);

    useEffect(() => {
        obtenerAgenciaRecaudadores();
        obtenerAgenciaCanales();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            {loading && <Spinner />}
            {alert && (
                <Alert
                    severity={alert.severity}
                    title={alert.title}
                    isForm={true}
                >
                    {alert.message}
                </Alert>
            )}
            <div className="fila-form">
                <div className="columna-form">
                    <label className={render !== "View" ? "required" : ""}>
                        Recaudador:
                    </label>
                    <Tooltip title="Recaudador">
                        <select className='select-form' value={recaudador?.idRecaudador} onChange={(e) => updateRecaudador(e.target.value)} disabled={render === 'View' ? true : null}>
                            <option value="">Seleccione el recaudador</option>
                            {opcRecaudador.map((opcion) => (
                                <option key={opcion.idRecaudador} value={opcion.idRecaudador}>
                                    {opcion.nomRecaudador}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label className={render !== "View" ? "required" : ""}>
                        Canal:
                    </label>
                    <Tooltip title="Canal">
                        <select className='select-form' value={canal?.idCanal} onChange={(e) => updateCanal(e.target.value)} disabled={render === 'View' ? true : null}>
                            <option value="">Seleccione el canal</option>
                            {opcCanal.map((opcion) => (
                                <option key={opcion.idCanal} value={opcion.idCanal}>
                                    {opcion.nomCanal}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label className={render !== "View" ? "required" : ""}>
                        Código Agencia:
                    </label>
                    <Tooltip title="Código Agencia">
                        <input className='input-form' type="text" maxLength="50" placeholder='Código Agencia' value={codAgencia} onChange={(e) => { handleChangeInput(e); updateCodAgencia(e.target.value) }} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label className={render !== "View" ? "required" : ""}>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' type="text" maxLength="100" placeholder='Descripción' value={dscAgencia} onChange={(e) => { handleChangeInput(e); updateDscAgencia(e.target.value) }} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button id="btn-accion" className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button id="btn-cancelar" className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};

export { Formulario };