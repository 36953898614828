import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import {
  errorMessages,
  successMessages,
} from "../../messages/commonMessages.jsx";
import { useOficinaService } from "../../../service/oficina.js";

export const useOficina = () => {
  const [tipoFacturador, setTipoFacturador] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdd, setIsAdd] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isView, setIsView] = useState();
  const [isDelete, setIsDelete] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [data, setData] = useState([]);
  const [facturadoresMap, setFacturadoresMap] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const { getOficinas, getOficinasFacturador, getFacturadores, patch } =
    useOficinaService();

  useEffect(() => {
    if (isSearch) {
      getListaOficinas();
    }

    return () => {
      setIsSearch(false);
    };
  }, [isSearch]);

  const getListaOficinas = async () => {
    try {
      setLoading(true);
      if (tipoFacturador) {
        await getOficinasFacturador(tipoFacturador).then((response) => {
          if (Array.isArray(response)) {
            setData(response);
          } else {
            showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
          }
        });
      } else {
        await getOficinas().then((response) => {
          if (Array.isArray(response)) {
            setData(response);
          } else {
            showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
          }
        });
      }
    } catch (error) {
      showAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
    } finally {
      setLoading(false);
    }
  };

  const getListaFacturadores = async () => {
    try {
      setLoading(true);
      const facturadoresMap = await getFacturadores();
      if (Array.isArray(facturadoresMap)) {
        setFacturadoresMap(facturadoresMap);
      } else {
        showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
      }
    } catch (error) {
      showAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
    } finally {
      setLoading(false);
    }
  };

  const deleteOficina = async (idOficina) => {
    return await patch(idOficina);
  };

  const showAlert = (severity, title, children) => {
    setAlert({ severity, title, children });

    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  const handleSave = () => {
    handleCloseDialog();
    showAlert("success", "Exitoso", successMessages.CHANGES_SAVED);
    setIsSearch(true);
  };

  const handleAdd = () => {
    setIsDialogOpen(true);
    setIsAdd(true);
    setSelectedRow("");
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsEdit(false);
    setIsAdd(false);
    setIsView(false);
    setIsDelete(false);
  };

  const handleCancel = () => {
    setLoading(false);
    handleCloseDialog();
  };

  const handleConsult = () => {
    setIsDialogOpen(true);
    setIsView(true);
  };

  const handleEdit = () => {
    setIsDialogOpen(true);
    setIsEdit(true);
  };

  const handleDelete = () => {
    setIsDialogOpen(true);
    setIsDelete(true);
  };

  const handleConfirmationDelete = async (idOficina) => {
    if (idOficina !== undefined) {
      setLoading(true);
      const result = await deleteOficina(idOficina);
      handleCancel();
      if (result !== undefined) {
        if (result?.success) {
          showAlert("success", "Exitoso", successMessages.DELETE_SUCCESSFUL);
          setIsSearch(true);
        } else {
          showAlert("error", "Error", result.message);
        }
      } else {
        showAlert("error", "Error", errorMessages.SERVER_ERROR);
      }
    } else {
      showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
    }
  };

  const handleRowSelectionChange = (selectedRowIds, data) => {
    const selectedIDs = new Set(selectedRowIds);
    const selectedRow = data.filter((row) => selectedIDs.has(row.id));
    setSelectedRow(selectedRow);
  };

  const handleGenerateReport = () => {
    showAlert("success", "Exitoso", successMessages.DOWNLOAD_SUCCESSFUL);
  };

  const handleOficinaSearch = async () => {
    setIsSearch(true);
  };

  const handleClean = () => {
    setTipoFacturador("");
  };

  const onChangeFacturador = async ({ target }) => {
    const { value } = target;
    setTipoFacturador(value);
  };

  const columnas = [
    { field: "codInterno", headerName: "Código Interno", flex: 1 },
    { field: "nombre", headerName: "Nombre Oficina", flex: 1 },
    { field: "tipoFacturador", headerName: "Facturador", flex: 1 },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <div className="button-action">
          <Tooltip title="Consultar">
            <button className="button-table" onClick={handleConsult}>
              <SearchIcon />
            </button>
          </Tooltip>
          <Tooltip title="Modificar">
            <button className="button-table" onClick={handleEdit}>
              <EditIcon />
            </button>
          </Tooltip>
          <Tooltip title="Eliminar">
            <button className="button-table" onClick={handleDelete}>
              <DeleteIcon />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];
  return {
    handleRowSelectionChange,
    handleOficinaSearch,
    handleGenerateReport,
    handleSave,
    handleConfirmationDelete,
    handleAdd,
    handleCancel,
    handleCloseDialog,
    handleClean,
    onChangeFacturador,
    getListaOficinas,
    getListaFacturadores,
    isDialogOpen,
    isEdit,
    isView,
    isAdd,
    isDelete,
    isSearch,
    loading,
    selectedRow,
    data,
    columnas,
    tipoFacturador,
    facturadoresMap,
    alert,
  };
};
