import { useState } from "react";
import { useParametrosService } from "../../../../service/parametros";
import {
  errorMessages,
  warningMessages,
} from "../../../../components/messages/commonMessages";

export const useFormulario = (onSave, onCancel, parametro, render) => {
  const [descripcion, setDescripcion] = useState(
    parametro !== "" ? parametro[0].dscParametro : ""
  );
  const [valor, setValor] = useState(
    parametro !== "" ? parametro[0].valParametro : ""
  );
  const [categoria, setCategoria] = useState(
    parametro !== "" ? parametro[0].categoria : ""
  );
  const [alertForm, setAlertForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const { post, put } = useParametrosService();

  let values = {};

  const addParametros = async (values) => {
    return await post(values);
  };

  const updateParametros = async (idParametro, values) => {
    return await put(idParametro, values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result;

    if (descripcion && valor && categoria) {
      setLoading(true);

      values.dscParametro = descripcion;
      values.valParametro = valor;
      values.categoria = categoria;

      if (render === "Add") {
        result = await addParametros(values);
      } else {
        let idParametro = parametro[0].idParametro;
        if (idParametro !== undefined) {
          result = await updateParametros(idParametro, values);
        }
      }

      if (result !== undefined) {
        if (result?.success) {
          onSave();
          setDescripcion("");
          setValor("");
          setCategoria("");
        } else {
          if (result.message !== errorMessages.SERVER_ERROR) {
            showAlert("warning", "Advertencia", result.message);
          } else {
            showAlert("error", "Error", result.message);
          }
        }
      } else {
        showAlert("error", "Error", errorMessages.SERVER_ERROR);
      }
    } else {
      showAlert("error", "Error", errorMessages.FORM_ERROR);
    }
  };

  const showAlert = (severity, title, children) => {
    setAlertForm({ severity, title, children });

    setTimeout(() => {
      setAlertForm(null);
    }, 5000);
  };

  const onChangeCategoria = async ({ target }) => {
    const { value, maxLength, validity } = target;

    if (validity.valid) {
      if (value.length >= maxLength) {
        showAlert(
          "warning",
          "Advertencia",
          warningMessages.MAX_LENGTH_INT(maxLength)
        );
      } else {
        setCategoria(value);
      }
    }
  };

  const onChangeValor = async ({ target }) => {
    const { value, maxLength } = target;
    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setValor(value);
    }
  };

  const onChangeDescripcion = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setDescripcion(value);
    }
  };

  return {
    onChangeDescripcion,
    onChangeValor,
    onChangeCategoria,
    handleSubmit,
    descripcion,
    valor,
    categoria,
    alertForm,
    loading,
  };
};
